import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Type for React components
// type ComponentType = React.ComponentType<any>;
//
// const loadComponent = (Component: ComponentType, elementId: string): void => {
//   const element = document.getElementById(elementId);
//
//   if (element) {
//     const root = ReactDOM.createRoot(element as HTMLElement);
//     root.render(<Component />);
//   }
// };

// Export the components and the loadComponent function for UMD
// export { SearchBar, SearchPage, loadComponent };
