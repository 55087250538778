import React, { createContext, useContext, ReactNode } from "react";

// Define the type for the settings
interface Settings {
  defaultPlaceholder: string;
  apiPageURL: string;
  searchPageURL: string;
  appID: string;
  apiKey: string;
  mobileVewPort: number | string;
}

// Create the context with a default value
const SettingsContext = createContext<Settings | undefined>(undefined);

// Create a provider component
interface SettingsProviderProps {
  settings: Partial<Settings>;
  children: ReactNode;
}

export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  settings,
  children,
}) => {
  const defaultSettings = {
    defaultPlaceholder: "Αναζητήστε εδώ προϊόντα",
    apiPageURL: "https://admin.searchx.gr/api/v1",
    searchPageURL: "https://searchx-components.commercex.gr/?query={query}",
    appID: "kVtrePaz1H",
    apiKey: "34acc9a2feeab20ea722b794fab7a5eda849d82003f284937b7278d7f8759dc1",
    mobileVewPort: "900px",
    productsIndex: `searchx_${settings.appID}_products_index`,
  };

  return (
    <SettingsContext.Provider value={{ ...defaultSettings, ...settings }}>
      {children}
    </SettingsContext.Provider>
  );
};

// Custom hook to use the settings context
export const useSettings = () => {
  const context = useContext(SettingsContext);

  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }

  return context;
};

export default SettingsContext;
