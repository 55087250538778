import { Highlight } from "react-instantsearch";
import React from "react";

import useSearchHistory from "../hooks/useSearchHistory";
import { ItemType } from "../types";

interface Props {
  item: ItemType;
}

// ts-ig
export default function SearchPageItem({ item, ...restProps }: Props) {
  const { addRecentSearchItem } = useSearchHistory();

  //TODO: Create a class to manage recent results
  const handleClick = () => {
    addRecentSearchItem(item);
    console.log("Analytics track search item selection:", item.id);
  };

  return (
    <div className="searchx__page-item">
      <a
        href={item.url}
        className="searchx__page-item-details"
        onClick={handleClick}
        {...restProps}
      >
        <img
          src={item.image}
          alt={`Product thumbnail for product ${item.object_id}`}
          className="searchx__page-item-image"
        />

        <Highlight attribute="title" hit={item} />

        <span className="searchx__page-item-price">
          {Intl.NumberFormat("en-DE", {
            style: "currency",
            currency: "EUR",
          }).format(item.price)}
        </span>
      </a>

      <button className="searchx__page-item-button">Καλάθι</button>
    </div>
  );
}
