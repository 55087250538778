export function replaceURLPlaceholders(
  path: string,
  searchItem: Record<string, unknown>,
): string {
  return path.replaceAll(/\{(\w+)\}/g, (match, key) => {
    const value = searchItem[key];

    if (value === undefined) {
      throw new Error(`Key '${key}' not found in the search item keys`);
    }

    if (typeof value !== "string") {
      throw new Error(`Search item '${key}' does not have a string value`);
    }

    return value;
  });
}

export function getNavigatorInfo() {
  return {
    browserCodeName: navigator?.appCodeName ?? "",
    browserName: navigator?.appName ?? "",
    browserVersion: navigator?.appVersion ?? "",
    cookiesEnabled: navigator?.cookieEnabled ?? "",
    browserLanguage: navigator?.language ?? "",
    browserOnline: navigator?.onLine ?? "",
    platform: navigator?.platform ?? "",
    userAgentHeader: navigator?.userAgent ?? "",
  };
}
