import React, { useState, useEffect } from "react";
import {
  Configure,
  InstantSearch,
  useHits,
  usePagination,
  useSortBy,
} from "react-instantsearch";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useMediaQuery } from "react-responsive";

import { searchClient } from "../utils/meilisearchClient";
import SearchPageItem from "./SearchPageItem";
import { ItemType } from "../types";
import { useSettings } from "../SettingsContext";

interface Props {
  initialQuery: string | null;
}

function SearchPageComponent({ initialQuery = "" }: Props) {
  const { mobileVewPort, appID } = useSettings();
  const SORT_ITEMS = [
    {
      label: "Αύξουσα Τιμή",
      value: `searchx_${appID}_products_index:price:asc`,
    },
    {
      label: "Φθίνουσα Τιμή",
      value: `searchx_${appID}_products_index:price:desc`,
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const { hits, results } = useHits();
  const [sortBy, setSortBy] = useState(SORT_ITEMS[0].value);
  const { currentRefinement, refine: refinePagination } = usePagination();
  const { refine: refineSortBy, currentRefinement: currentSortBy } = useSortBy({
    items: SORT_ITEMS,
  });
  const totalPages = Math.ceil(
    (results?.nbHits ?? 1) / (results?.hitsPerPage ?? 1),
  );
  const hasHits = hits.length > 0;
  const totalHits = results?.nbHits ?? 0;

  // Check if the viewport is mobile
  const isMobile = useMediaQuery({
    // We can pass a number or a string as the breakpoint
    query: `(max-width: ${typeof mobileVewPort === "string" ? `${mobileVewPort.replace("px", "")}px` : `${mobileVewPort}px`})`,
  });

  // Apply the default sort option on component mount
  useEffect(() => {
    refineSortBy(sortBy);
  }, [sortBy, refineSortBy]);

  // Set the loading state to false after 200ms
  useEffect(() => {
    const timer = setTimeout(function () {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <InstantSearch
      indexName={`searchx_${appID}_products_index`}
      searchClient={searchClient}
    >
      <div className="searchx__page">
        <div className="searchx__page-heading">
          {initialQuery ? (
            <h1 className="searchx__page-heading-title">
              Αποτελέσματα για "{initialQuery}"
            </h1>
          ) : (
            <h1 className="searchx__page-heading-title">
              Αποτελέσματα Αναζήτησης
            </h1>
          )}
          {!isLoading && (
            <h2 className="searchx__page-heading-subtitle">
              {totalHits} Προϊόντα
            </h2>
          )}
        </div>

        {!isLoading && hasHits && (
          <div className="searchx__page-options">
            <select
              value={currentSortBy}
              className="searchx__page-sorting"
              aria-label="Sorting menu"
              onChange={(e) => {
                const selectedValue = e.target.value;
                setSortBy(selectedValue);
                refineSortBy(selectedValue);
              }}
            >
              {SORT_ITEMS.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <div className="searchx__page-pagination">
              <button
                disabled={currentRefinement === 0}
                className="searchx__page-pagination-button"
                aria-label="Previous page button"
                onClick={() => refinePagination(currentRefinement - 1)}
              >
                <ChevronLeftIcon aria-label="Chevron left icon" />
              </button>
              {totalPages > 1 && !isMobile ? (
                <span>
                  Σελίδα {currentRefinement + 1} απο {totalPages} σελίδες
                </span>
              ) : (
                <span>Σελίδα {currentRefinement + 1}</span>
              )}
              <button
                disabled={currentRefinement === totalPages - 1}
                className="searchx__page-pagination-button"
                aria-label="Next page button"
                onClick={() => refinePagination(currentRefinement + 1)}
              >
                <ChevronRightIcon aria-label="Chevron right icon" />
              </button>
            </div>
          </div>
        )}

        {!isLoading &&
          (hasHits ? (
            <div className="searchx__page-results">
              {(hits as Array<ItemType>).map((item) => (
                <SearchPageItem key={item.object_id} item={item as ItemType} />
              ))}
            </div>
          ) : (
            <div className="searchx__page-no-results">
              <MagnifyingGlassIcon
                className="searchx__page-no-results-icon"
                aria-label="Magnifying glass icon"
              />
              <h3 className="searchx__page-no-results-title">
                Δεν βρέθηκαν αποτελέσματα
              </h3>
            </div>
          ))}

        {!isLoading && hasHits && (
          <div className="searchx__page-options">
            <select
              value={currentSortBy}
              className="searchx__page-sorting"
              aria-label="Sorting menu"
              onChange={(e) => refineSortBy(e.target.value)}
            >
              {SORT_ITEMS.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <div className="searchx__page-pagination">
              <button
                disabled={currentRefinement === 0}
                className="searchx__page-pagination-button"
                aria-label="Previous page button"
                onClick={() => refinePagination(currentRefinement - 1)}
              >
                <ChevronLeftIcon aria-label="Chevron left icon" />
              </button>
              {totalPages > 1 && !isMobile ? (
                <span>
                  Σελίδα {currentRefinement + 1} απο {totalPages} σελίδες
                </span>
              ) : (
                <span>Σελίδα {currentRefinement + 1}</span>
              )}
              <button
                disabled={currentRefinement === totalPages - 1}
                className="searchx__page-pagination-button"
                aria-label="Next page button"
                onClick={() => refinePagination(currentRefinement + 1)}
              >
                <ChevronRightIcon aria-label="Chevron right icon" />
              </button>
            </div>
          </div>
        )}
      </div>
    </InstantSearch>
  );
}

export default function SearchPage() {
  const { appID } = useSettings();
  const urlParams = new URLSearchParams(window.location.search);
  const initialQuery = urlParams.get("query") || "";

  return (
    <InstantSearch
      indexName={`searchx_${appID}_products_index`}
      searchClient={searchClient}
    >
      <Configure query={initialQuery} />
      <SearchPageComponent initialQuery={initialQuery} />
    </InstantSearch>
  );
}
