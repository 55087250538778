import { useState, useEffect } from "react";
import { ItemType } from "../types";

const useSearchHistory = (storageKey = "searchx") => {
  const [recentSearches, setRecentSearches] = useState<Array<ItemType>>([]);

  // Initialize storage and fetch recent searches
  useEffect(() => {
    const initializeStorage = () => {
      const storedData = localStorage.getItem(storageKey);
      if (!storedData) {
        localStorage.setItem(
          storageKey,
          JSON.stringify({ recentSearches: [] }),
        );
        return [];
      } else {
        const searches = JSON.parse(storedData).recentSearches;
        return searches.slice(-5); // Return only the last five items
      }
    };

    setRecentSearches(initializeStorage());
  }, [storageKey]);

  // Add a new search item to the recent searches
  const addRecentSearchItem = (item: ItemType) => {
    setRecentSearches((prevSearches) => {
      // Remove the item if it already exists to prevent duplicates
      const filteredSearches = prevSearches.filter(
        (search) => search.id !== item.id,
      );
      // Add the new item to the start of the list
      const newSearches = [item, ...filteredSearches].slice(0, 5); // Keep only the last five entries
      // Update localStorage
      localStorage.setItem(
        storageKey,
        JSON.stringify({ recentSearches: newSearches }),
      );
      return newSearches;
    });
  };

  const removeRecentSearchItems = () => {
    localStorage.setItem(storageKey, JSON.stringify({ recentSearches: [] }));
    setRecentSearches([]);
  };

  return { recentSearches, addRecentSearchItem, removeRecentSearchItems };
};

export default useSearchHistory;
