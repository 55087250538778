import { Highlight } from "react-instantsearch";
import React from "react";
import axios from "axios";

import useSearchHistory from "../hooks/useSearchHistory";
import { ItemType } from "../types";
import { useSettings } from "../SettingsContext";
import { getNavigatorInfo } from "../utils/methods";

interface Props {
    item: ItemType;
}

export default function SearchBarItem({ item, ...restProps }: Props) {
    const { addRecentSearchItem } = useSearchHistory();
    const { apiPageURL, apiKey } = useSettings();

    const handleClick = async () => {
        try {
            addRecentSearchItem(item);

            console.log("Analytics track search item selection:", item.object_id);
            await axios.post(
                `${apiPageURL}/applications/9c729919-9bc2-4d31-89e6-ee87050d7eee/selections?api_key=${apiKey}`,
                {
                    type: "product",
                    model: item,
                    navigator: getNavigatorInfo(),
                },
            );
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <a
            href={item.url}
            className="searchx__popup-item"
            onClick={handleClick}
            {...restProps}
        >
            <img
                src={item.image}
                alt={`Product thumbnail for product ${item.object_id}`}
                className="searchx__popup-item-image"
            />
            <Highlight attribute="title" hit={item} />
        </a>
    );
}
