import React, { useCallback, useState, useEffect, useRef } from "react";
import {
    useSearchBox,
    useInfiniteHits,
    Configure,
    InstantSearch,
} from "react-instantsearch";
import {
    MagnifyingGlassIcon,
    MagnifyingGlassCircleIcon,
    XMarkIcon,
    XCircleIcon,
} from "@heroicons/react/24/outline";
import { useMediaQuery } from "react-responsive";

import SearchBarItem from "./SearchBarItem";
import { searchClient } from "../utils/meilisearchClient";
import { useSettings } from "../SettingsContext";
import { getNavigatorInfo, replaceURLPlaceholders } from "../utils/methods";
import useSearchHistory from "../hooks/useSearchHistory";
import { ItemType } from "../types";
import axios from "axios";

interface Props {
    initialQuery: string | null;
}

function SearchBarComponent({ initialQuery = "" }: Props) {
    const { query, refine } = useSearchBox();
    const { hits, showMore, isLastPage, results } = useInfiniteHits();
    const [showResultsModal, setShowResultsModal] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const {
        defaultPlaceholder,
        searchPageURL,
        mobileVewPort,
        apiPageURL,
        apiKey,
    } = useSettings();
    const { recentSearches, removeRecentSearchItems } = useSearchHistory();
    const hasHits = hits.length > 0;
    const totalHits = results?.nbHits ?? 0;
    const hasTotalHits = totalHits > 0;

    // Check if the viewport is mobile
    const isMobile = useMediaQuery({
        // We can pass a number or a string as the breakpoint
        query: `(max-width: ${typeof mobileVewPort === "string" ? `${mobileVewPort.replace("px", "")}px` : `${mobileVewPort}px`})`,
    });

    useEffect(() => {
        // If the search query is empty and the results modal is not shown, clear the search query
        if (!initialQuery && !showResultsModal) {
            refine("");
        }
    }, [initialQuery, showResultsModal, refine]);

    useEffect(() => {
        // If there is an initial query and the results modal is shown, set the search query
        if (initialQuery) {
            refine(initialQuery);
        }
    }, [initialQuery, refine]);

    // Handle clicks outside the search component to close the modal
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                ref.current &&
                !ref?.current
                    ?.querySelector(".searchx__form")
                    ?.contains(event.target as Node) &&
                !ref?.current
                    ?.querySelector(".searchx__popup")
                    ?.contains(event.target as Node)
            ) {
                setShowResultsModal(false);
            }
        };

        const handleClickEscape = (event: KeyboardEvent) => {
            if (event.key === "Escape" || event.key === "Esc") {
                // 'Esc' for older browsers
                event.preventDefault(); // Prevent any default behavior

                setShowResultsModal(false);
                ref?.current?.querySelector("input")?.blur();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleClickEscape);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleClickEscape);
        };
    }, [refine]);

    // Handle search submission, currently just logging
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();

            console.log("Analytics track search query:", query);
            await axios.post(
                `${apiPageURL}/applications/9c729919-9bc2-4d31-89e6-ee87050d7eee/terms?api_key=${apiKey}`,
                {
                    term: query || "EMPTY",
                    navigator: getNavigatorInfo(),
                },
            );
        } catch (err) {
            console.log(err);
        } finally {
            // Assuming navigation is handled here, uncomment if needed:
            window.location.href = replaceURLPlaceholders(searchPageURL, { query });
        }
    };

    // Infinite scrolling logic
    const observer = useRef<IntersectionObserver | null>(null);
    const lastHitElementRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && !isLastPage) {
                    showMore();
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLastPage, showMore],
    );

    // @ts-ignore
    // @ts-ignore
    return (
        <div ref={ref} className="searchx__container">
            {isMobile ? (
                <button
                    className="searchx__mobile-trigger"
                    aria-label="Mobile menu trigger"
                    onClick={() => setShowResultsModal(true)}
                >
                    {showResultsModal ? (
                        <XMarkIcon
                            className="searchx__mobile-trigger-icon"
                            aria-label="Close icon"
                        />
                    ) : (
                        <MagnifyingGlassCircleIcon
                            className="searchx__mobile-trigger-icon"
                            aria-label="Magnifying glass circle icon"
                        />
                    )}
                </button>
            ) : (
                <form className="searchx__form" onSubmit={handleSubmit}>
                    <MagnifyingGlassIcon
                        className="searchx__form-glass-icon"
                        aria-label="Magnifying glass circle icon"
                    />

                    <input
                        type="text"
                        value={query}
                        className="searchx__input"
                        placeholder={defaultPlaceholder}
                        aria-label="Search input field"
                        onChange={(e) => refine(e.target.value)}
                        onFocus={() => setShowResultsModal(true)}
                    />

                    {query && (
                        <XCircleIcon
                            className="searchx__form-close-icon"
                            aria-label="Close icon"
                            onClick={() => {
                                refine("");
                                ref?.current?.querySelector("input")?.focus();
                            }}
                        />
                    )}
                </form>
            )}

            {showResultsModal && (
                <div className="searchx__popup">
                    {isMobile && (
                        <div className="searchx__form-container">
                            <form className="searchx__form" onSubmit={handleSubmit}>
                                <MagnifyingGlassIcon
                                    className="searchx__form-glass-icon"
                                    aria-label="Magnifying glass icon"
                                />

                                <input
                                    type="text"
                                    value={query}
                                    className="searchx__input"
                                    placeholder={defaultPlaceholder}
                                    aria-label="Search input field"
                                    onChange={(e) => refine(e.target.value)}
                                    onFocus={() => setShowResultsModal(true)}
                                />

                                {query && (
                                    <XCircleIcon
                                        className="searchx__form-close-icon"
                                        aria-label="Close icon"
                                        onClick={() => {
                                            refine("");
                                            ref?.current?.querySelector("input")?.focus();
                                        }}
                                    />
                                )}
                            </form>
                        </div>
                    )}

                    {hasHits ? (
                        <div className="searchx__popup-results">
                            <h3 className="searchx__popup-results-title">
                                Προϊόντα{" "}
                                {hasTotalHits && (
                                    <span className="searchx__popup-results-counter">
                                        {totalHits}
                                    </span>
                                )}
                            </h3>
                            {(hits as Array<ItemType>).map((item, index) => (
                                <div
                                    key={item.object_id}
                                    ref={hits.length === index + 1 ? lastHitElementRef : null}
                                >
                                    <SearchBarItem item={item} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="searchx__popup-no-results">
                            <MagnifyingGlassIcon
                                className="searchx__popup-no-results-icon"
                                aria-label="Magnifying glass icon"
                            />
                            <h3 className="searchx__popup-no-results-title">
                                Δεν βρέθηκαν αποτελέσματα για "{query}"
                            </h3>
                        </div>
                    )}

                    {recentSearches.length > 0 && (
                        <div className="searchx__popup-recent-searches">
                            <h3 className="searchx__popup-recent-searches-title">
                                Πρόσφατες αναζητήσεις
                            </h3>

                            {recentSearches.map((item) => (
                                <SearchBarItem key={item.object_id} item={item} />
                            ))}

                            <button
                                className="searchx__popup-clear-recent-searches"
                                onClick={removeRecentSearchItems}
                            >
                                Διαγραφή ιστορικού
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default function SearchBar() {
    const { appID } = useSettings();
    const urlParams = new URLSearchParams(window.location.search);
    const initialQuery = urlParams.get("query") || "";

    return (
        <InstantSearch
            indexName={`searchx_${appID}_products_index`}
            searchClient={searchClient}
        >
            <Configure query={initialQuery} />
            <SearchBarComponent initialQuery={initialQuery} />
        </InstantSearch>
    );
}
